import { useContext, useEffect } from 'react';
import { loadHomeHub } from 'data/homeHub/api';
import { ApiError, makeErrorFromApi, OptionalApiError } from 'lib/error';

import { buildUrlStrict } from 'lib/url';
import {
  Address,
  Country,
  HomeId,
  InviteToken,
  Member,
  Permissions,
} from 'types';
import { usePlan } from 'data/plan/usePlan';
import { useTranslation } from 'data/translation/useTranslation';
import { useError } from 'data/error/useError';
import redirect from 'lib/redirect';
import CSRFContext from 'data/csrf/context';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { PlanType } from 'lib/plans';

const DEFAULT_HOME_HUB_RETRIES = 10;

export const LOAD_HOME_HUB_ERROR_TYPE = 'LOAD_HOME_HUB_ERROR_TYPE';
export const loadHomeHubErrors = {
  NOT_FOUND: 'NOT_FOUND',
};

export type HomeHubData = {
  name: string;
  planType: PlanType;
  maxCapacity: number;
  inviteToken: InviteToken;
  address: Address | null;
  homeId: HomeId;
  members: Array<Member>;
  userCountry: Country;
  permissions: Permissions;
};

export type useHomeHubProps = {
  isLoadingHomeHub: boolean;
  homeHubData?: HomeHubData;
  permissions?: Permissions;
  error: OptionalApiError;
};

const useHomeHub = ({
  withRetries,
}: {
  withRetries: boolean;
}): useHomeHubProps => {
  const { locale } = useTranslation();
  const { error, setError } = useError();
  const { planType } = usePlan();
  const { csrfClient } = useContext(CSRFContext);
  const queryResult = useQuery<HomeHubData, AxiosError<ApiError>>({
    queryKey: ['homeHub'],
    queryFn: () => {
      return loadHomeHub(csrfClient);
    },
    retry: withRetries ? DEFAULT_HOME_HUB_RETRIES : false,
  });

  useEffect(() => {
    if (queryResult.status === 'error') {
      setError(makeErrorFromApi(LOAD_HOME_HUB_ERROR_TYPE, queryResult.error));
    }
  }, [queryResult.data, queryResult.status, queryResult.error, setError]);

  useEffect(() => {
    const planTypeFromHomeHub = queryResult?.data && queryResult?.data.planType;

    if (planTypeFromHomeHub && planType !== planTypeFromHomeHub) {
      redirect(buildUrlStrict(planTypeFromHomeHub, 'HOME_HUB', locale));
    }
  }, [queryResult, locale, planType]);

  return {
    isLoadingHomeHub: queryResult.isLoading,
    homeHubData: queryResult?.data,
    permissions: queryResult.data && queryResult.data.permissions,
    error,
  };
};

export default useHomeHub;
