import {FamilyAPI} from "services/api";

export type UserDataResponse = {
  country: string;
  isNewUser: boolean;
  name: string;
  smallProfileImageUrl: string | null;
  largeProfileImageUrl: string | null;
};

export const apiToApp = ({
  largeProfileImageUrl,
  ...rest
}: UserDataResponse) => ({
  ...rest,
  avatar: largeProfileImageUrl,
});

export const loadUserData = async () => {
  return FamilyAPI.V1.get<UserDataResponse>('user/').then(({ data }) => apiToApp(data));
};
