import { ErrorCode, ErrorType, OptionalApiError } from 'lib/error';

export const isErrorOfType = (error: OptionalApiError, type: ErrorType) =>
  error?.type === type;

export const isErrorOfTypeAndCode = (
  error: OptionalApiError,
  type: ErrorType,
  code: ErrorCode,
) =>
  isErrorOfType(error, type) &&
  // The second condition is there to handle responses from #skywalker service
  (error?.code === code || error?.data?.message === code);
