import { isArabicLocale, Locale } from 'lib/i18n';
import { generateFullPath } from 'lib/url';

const phoneCoversImageMENA = '/img/phone-covers-mena.png';
const phoneCoversImageIsrael = '/img/phone-covers-il.png';
const phoneCoversImageDefault = '/img/phone-covers.png';

const chillmatic = '/img/duo-phones/chillmatic.png';
const cardio = '/img/duo-phones/cardio.png';
const habibAlby = '/img/duo-phones/habib-alby.png';
const happyHitsHebrew = '/img/duo-phones/happy-hits-hebrew.png';
const onTheRoadHebrew = '/img/duo-phones/on-the-road-hebrew.png';
const rapCaviar = '/img/duo-phones/rap-caviar.png';
const chillnama = '/img/duo-phones/chillnama.png';
const bollywoodWorkout = '/img/duo-phones/bollywood-workout.png';
const justChill = '/img/duo-phones/just-chill.png';
const refreshYourDay = '/img/duo-phones/refresh-your-day.png';
const rebahan = '/img/duo-phones/rebahan.png';
const bicaraCinta = '/img/duo-phones/bicara-cinta.png';
const chillLah = '/img/duo-phones/chill-lah.png';
const partyLife = '/img/duo-phones/party-life.png';
const weekendChillout = '/img/duo-phones/weekend-chillout.png';
const hotDanceTracksTw = '/img/duo-phones/hot-dance-tracks-tw.png';
const cantoPopDuets = '/img/duo-phones/cantopop-duets.png';
const chillHits = '/img/duo-phones/chill-hits.png';
const thoaiMaiGacChanLen = '/img/duo-phones/thoai-mai-gac-chan-len.png';
const tiecTung = '/img/duo-phones/tiec-tung.png';
const chillaxKaMuna = '/img/duo-phones/chillax-ka-muna.png';
const goodTimes = '/img/duo-phones/good-times.png';
const jazzVibes = '/img/duo-phones/jazz-vibes.png';
const offPop = '/img/duo-phones/off-pop.png';
const chilledPopHits = '/img/duo-phones/chilled-pop-hits.png';
const hitsDuMoment = '/img/duo-phones/hits-du-moment.png';
const gardeLaPeche = '/img/duo-phones/garde-la-peche.png';
const tKoffiehuis = '/img/duo-phones/t-koffiehuis.png';
const feelingGoodFeelingGreat =
  '/img/duo-phones/feeling-good-feeling-great.png';
const lungtSkont = '/img/duo-phones/lugnt-skont.png';
const pepplaterTillTrainingspasset =
  '/img/duo-phones/pepplater-till-traningspasset.png';
const meGustas = '/img/duo-phones/me-gustas.png';
const puraEnergia = '/img/duo-phones/pura-energia.png';
const lavoraColSorriso = '/img/duo-phones/lavora-col-sorriso.png';
const allenamentoACasa = '/img/duo-phones/allenamento-a-casa.png';
const arkadaCalsin = '/img/duo-phones/arkada-calsin.png';
const hareketeGec = '/img/duo-phones/harekete-gec.png';

// Middle East and North Africa markets which require changed imagery.
const MENA_MARKETS = new Set<Locale>([
  'ae-ar',
  'ae-en',
  'bh-en',
  'bh-ar',
  'dz-ar',
  'dz-fr',
  'eg-ar',
  'eg-en',
  'jo-ar',
  'jo-en',
  'kw-ar',
  'kw-en',
  'lb-ar',
  'lb-en',
  'ma-ar',
  'ma-fr',
  'om-ar',
  'om-en',
  'ps-ar',
  'ps-en',
  'qa-ar',
  'qa-en',
  'sa-ar',
  'sa-en',
  'tn-ar',
  'tn-fr',
]);

export const shouldUseImageryForIsrael = (selectedLocale: Locale): boolean => {
  return selectedLocale === 'il-he';
};

const shouldUseImageryForMENA = (selectedLocale: Locale): boolean => {
  return MENA_MARKETS.has(selectedLocale);
};

export const getFamilySubLandingPageCoversUrl = (locale: Locale): string => {
  if (shouldUseImageryForMENA(locale))
    return generateFullPath(phoneCoversImageMENA);
  if (shouldUseImageryForIsrael(locale))
    return generateFullPath(phoneCoversImageIsrael);
  return generateFullPath(phoneCoversImageDefault);
};

const getDuoSubLandingPageLeftPhoneFileName = (locale: Locale) => {
  switch (locale) {
    case 'il-he':
      return onTheRoadHebrew;
    case 'in-en':
      return chillnama;
    case 'th-th':
    case 'th-en':
      return justChill;
    case 'id-id':
    case 'id-en':
      return rebahan;
    case 'my-en':
    case 'my-ms':
    case 'sg-en':
      return chillLah;
    case 'tw':
      return weekendChillout;
    case 'hk-en':
    case 'hk-zh':
      return cantoPopDuets;
    case 'vn-vi':
    case 'vn-en':
      return thoaiMaiGacChanLen;
    case 'ph-en':
      return chillaxKaMuna;
    case 'de':
    case 'at':
    case 'ch-de':
      return jazzVibes;
    case 'uk':
      return chilledPopHits;
    case 'fr':
      return hitsDuMoment;
    case 'nl':
      return tKoffiehuis;
    case 'se':
      return lungtSkont;
    case 'es':
      return meGustas;
    case 'it':
      return lavoraColSorriso;
    case 'tr-tr':
    case 'tr-en':
      return arkadaCalsin;
    default:
      return chillmatic;
  }
};

export const getDuoSubLandingPageLeftPhoneSource = (locale: Locale): string => {
  if (isArabicLocale(locale)) return generateFullPath(rapCaviar);
  return generateFullPath(getDuoSubLandingPageLeftPhoneFileName(locale));
};

const getDuoSubLandingPageRightPhoneFileName = (locale: Locale) => {
  switch (locale) {
    case 'il-he':
      return happyHitsHebrew;
    case 'in-en':
      return bollywoodWorkout;
    case 'th-th':
    case 'th-en':
      return refreshYourDay;
    case 'id-id':
    case 'id-en':
      return bicaraCinta;
    case 'my-en':
    case 'my-ms':
    case 'sg-en':
      return partyLife;
    case 'tw':
      return hotDanceTracksTw;
    case 'hk-en':
    case 'hk-zh':
      return chillHits;
    case 'vn-vi':
    case 'vn-en':
      return tiecTung;
    case 'ph-en':
      return goodTimes;
    case 'de':
    case 'at':
    case 'ch-de':
      return offPop;
    case 'fr':
      return gardeLaPeche;
    case 'nl':
      return feelingGoodFeelingGreat;
    case 'se':
      return pepplaterTillTrainingspasset;
    case 'es':
      return puraEnergia;
    case 'it':
      return allenamentoACasa;
    case 'tr-tr':
    case 'tr-en':
      return hareketeGec;
    default:
      return cardio;
  }
};
export const getDuoSubLandingPageRightPhoneSource = (
  locale: Locale,
): string => {
  if (isArabicLocale(locale)) return generateFullPath(habibAlby);
  return generateFullPath(getDuoSubLandingPageRightPhoneFileName(locale));
};
