import { useEffect } from 'react';
import redirect from 'lib/redirect';
import { Permissions } from 'types';
import { ApiError, OptionalApiError } from 'lib/error';
import {
  LOAD_HOME_HUB_ERROR_TYPE,
  loadHomeHubErrors,
} from 'data/homeHub/useHomeHub';
import { getLoggedInMember } from 'lib/members';
import { HomeHubData } from 'data/homeHub/useHomeHub';

type RouteOrRouteFactory = string | (() => string);

function getRouteString(route: RouteOrRouteFactory): string {
  return typeof route === 'function' ? route() : route;
}
export function useRedirect({
  route,
  condition,
}: {
  route: RouteOrRouteFactory;
  condition: boolean;
}) {
  useEffect(() => {
    if (condition) {
      const routeAsString = getRouteString(route);
      redirect(routeAsString);
    }
  }, [condition, route]);
}

export function useRedirectIfNotOnboardedMaster(
  route: RouteOrRouteFactory,
  permissions: Permissions | undefined,
): void {
  const routeAsString = getRouteString(route);

  useRedirect({
    route: routeAsString,
    condition: permissions !== undefined && permissions.onboardingRequired,
  });
}

const isNotFoundHomeHubError = ({ type, code }: ApiError) =>
  type === LOAD_HOME_HUB_ERROR_TYPE && code === loadHomeHubErrors.NOT_FOUND;

export function useRedirectOnNoHomeHub(
  route: RouteOrRouteFactory,
  error: OptionalApiError,
): void {
  const routeAsString = getRouteString(route);
  useRedirect({
    route: routeAsString,
    condition:
      error !== undefined && (error ? isNotFoundHomeHubError(error) : false),
  });
}

export function useRedirectIfNotMaster(
  route: RouteOrRouteFactory,
  homeHubData: HomeHubData | undefined,
): void {
  const routeAsString = getRouteString(route);

  useRedirect({
    route: routeAsString,
    condition:
      (homeHubData?.members &&
        !getLoggedInMember(homeHubData.members)?.isMaster) ??
      false,
  });
}

export function useRedirectOnMasterHasAlreadySetAddress(
  route: RouteOrRouteFactory,
  permissions: Permissions | undefined,
): void {
  const routeAsString = getRouteString(route);

  useRedirect({
    route: routeAsString,
    condition: (permissions && !permissions.addressUpdateRequired) ?? false,
  });
}
