import React, { ReactNode, useEffect } from 'react';
import DefaultLoadingScreen from 'components/Loading/DefaultLoadingScreen';
import useInviter, { LOAD_INVITER_ERROR_TYPE } from 'data/inviter/useInviter';
import { useMasthead } from 'data/masthead/useMasthead';
import { usePlan } from 'data/plan/usePlan';
import { useTranslation } from 'data/translation/useTranslation';
import { useUserData } from 'data/user/useUserData';
import { Locale } from 'lib/i18n';
import { trackAndRedirect, trackEvent } from 'lib/trackingV2';
import {
  joinLandingPageAuth as joinLandingPageAuthV2,
  joinLandingPageNonAuth as joinLandingPageNonAuthV2,
} from 'lib/trackingEventsV2';
import { buildUrlStrict } from 'lib/url';
import { useRouter } from 'next/router';

import GeneralError from 'containers/Error/GeneralError';
import InvitationExpiredError from 'containers/Error/InvitationExpiredError';
import PlanAlreadyFullError from 'containers/Error/PlanAlreadyFullError';
import { useError } from 'data/error/useError';
import { isErrorOfTypeAndCode } from 'data/error/utils';

type TrackPageViewProps = {
  onPageView: () => void;
  children: ReactNode;
};

const TrackPageView = ({ onPageView, children }: TrackPageViewProps) => {
  useEffect(() => {
    onPageView();
  }, [onPageView]);
  return <>{children}</>;
};

export function withInviterLoading(SubLandingPageComponent: any) {
  return () => {
    const { planType } = usePlan();
    const router = useRouter();
    const mastheadProps = useMasthead(router.locale as Locale);
    const { error, errors } = useError();
    const { userData } = useUserData();
    const { locale } = useTranslation();

    const { inviteToken, isLoadingInviter, inviterData } = useInviter();

    const inviterError = errors.find(
      e => e?.type === LOAD_INVITER_ERROR_TYPE && e?.status !== 401,
    );

    const shouldDisplayGeneralError = typeof inviterError !== 'undefined';

    if (error) {
      if (
        isErrorOfTypeAndCode(error, LOAD_INVITER_ERROR_TYPE, 'PLAN_IS_FULL')
      ) {
        return <PlanAlreadyFullError error={error} />;
      }

      if (
        isErrorOfTypeAndCode(error, LOAD_INVITER_ERROR_TYPE, 'INVITE_NOT_FOUND')
      )
        return <InvitationExpiredError error={error} />;
    }

    if (shouldDisplayGeneralError) {
      return <GeneralError error={inviterError} />;
    }

    if (isLoadingInviter || mastheadProps.isLoading) {
      return <DefaultLoadingScreen darkMode={false} />;
    }

    const isAnonymous = !userData;

    const eventV2 = isAnonymous
      ? joinLandingPageNonAuthV2
      : joinLandingPageAuthV2;

    const accountConfirmationUrl = buildUrlStrict(
      planType,
      'SUB_CONFIRM_ACCOUNT',
      locale,
      {
        token: inviteToken!,
      },
    );
    return (
      <TrackPageView
        onPageView={() => {
          return trackEvent(eventV2.pageView(planType));
        }}
      >
        <SubLandingPageComponent
          joinUrl={accountConfirmationUrl}
          termsUrl={inviterData?.legalTermsUrl}
          onJoinClick={() => {
            return trackAndRedirect(
              eventV2.join(planType),
              accountConfirmationUrl,
            );
          }}
          onTermsClick={() => {
            return trackAndRedirect(
              eventV2.toc(planType),
              inviterData?.legalTermsUrl!,
            );
          }}
          mastheadData={mastheadProps.data}
        />
      </TrackPageView>
    );
  };
}
