import styled from 'styled-components';
import { TextLink } from '@spotify-internal/encore-web';

const UnderlinedLink = styled(TextLink)<{ $darkMode?: boolean }>`
  color: ${props =>
    props.$darkMode ? props.theme.invertedTextColor : props.theme.linkColor};
  text-decoration: underline;

  &[href]:hover:not([disabled]) {
    color: ${props => props.theme.linkColor};
  }
`;

export default UnderlinedLink;
