import { loadUserData } from 'data/user/api';
import { ApiError, makeErrorFromApi } from 'lib/error';
import { useEffect } from 'react';
import { useError } from 'data/error/useError';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export type UserData = {
  name: string;
  country: string;
  avatar: string | null;
  smallProfileImageUrl: string | null;
  isNewUser: boolean;
};

type UseUserDataType = {
  isLoading: boolean;
  error?: ApiError;
  userData: UserData | undefined;
};

export const LOAD_USER_DATA_ERROR_TYPE = 'LOAD_USER_DATA_ERROR_TYPE';

export function useUserData(): UseUserDataType {
  const { setError } = useError();

  const { data, error, status, isLoading } = useQuery<
    UserData,
    AxiosError<ApiError>
  >({
    queryKey: ['loadUserData'],
    queryFn: loadUserData,
    retry: false,
  });

  useEffect(() => {
    if (status === 'error') {
      setError(makeErrorFromApi(LOAD_USER_DATA_ERROR_TYPE, error));
    }
  }, [data, status, setError, error]);

  return {
    isLoading,
    userData: data,
  };
}
