import PlanAlreadyFull from 'components/Error/PlanAlreadyFull';
import { buildUrlStrict } from 'lib/url';
import React from 'react';
import { ApiError } from 'lib/error';
import { usePlan } from 'data/plan/usePlan';
import { useErrorPageView } from 'data/tracking/useErrorPageView';
import { useAnalyticsPageView } from 'data/tracking/useAnalyticsPageView';
import { useTranslation } from 'data/translation/useTranslation';

type PlanAlreadyFullContainerProps = {
  error: ApiError;
};
const PlanAlreadyFullContainer = (props: PlanAlreadyFullContainerProps) => {
  const { error } = props;
  const { planType } = usePlan();
  const { locale } = useTranslation();

  useErrorPageView('plan_is_full', error);
  useAnalyticsPageView(planType, '/error/plan-is-full/');

  return (
    <PlanAlreadyFull
      landingPageLink={buildUrlStrict(planType, 'PREMIUM_LANDING_PAGE', locale)}
      planType={planType}
    />
  );
};
export default PlanAlreadyFullContainer;
