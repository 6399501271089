import { UserData } from 'data/inviter/useInviter';
import { FamilyAPI } from 'services/api';

export const apiToApp = ({
  homeId,
  inviter,
  isLoggedInUserMaster,
  planHoppingRestrictionsApply,
  joinRestrictions,
  checkFailureReason,
}: any): UserData => ({
  ...inviter,
  homeId,
  isLoggedInUserMaster,
  planHoppingRestrictionsApply,
  joinRestrictions: joinRestrictions || [],
  checkFailureReason,
});

export const loadInviter = (inviteToken: string) => {
  return FamilyAPI.V1.get(`invite/${inviteToken}/`).then(({ data }) =>
    apiToApp(data),
  );
};
