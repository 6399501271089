import React from 'react';
import styled, { useTheme } from 'styled-components';
import { BareLayout, UnpaddedLayout } from 'components/Layout';
import { Loader } from 'components/Loading/Loader';

// minimum height so the loader is displayed properly on account pages
const Bare = styled(BareLayout)<{ darkMode: boolean }>`
  min-height: 200px;
`;

type ScreenProps = {
  headless?: boolean;
  darkMode: boolean;
};

const DefaultLoadingScreen = ({ headless, darkMode }: ScreenProps) => {
  const Layout = headless ? Bare : UnpaddedLayout;
  const theme = useTheme();
  return (
    <Layout
      darkMode={darkMode}
      style={{
        background: darkMode ? theme.invertedForeground : theme.foreground,
        color: darkMode ? theme.invertedTextColor : theme.textColor,
      }}
    >
      <Loader darkMode={darkMode} />
    </Layout>
  );
};

export default DefaultLoadingScreen;
