import { AccessControl, Feature, Permissions } from 'types';

export const getPermissions = (
  accessControl: AccessControl = {
    planHasFreeSlots: false,
    onboardingRequired: false,
    addressUpdateRequired: false,
  },
  features: Array<Feature> = [],
): Permissions => ({
  planHasFreeSlots: accessControl.planHasFreeSlots,
  onboardingRequired: accessControl.onboardingRequired,
  addressUpdateRequired: accessControl.addressUpdateRequired,
  // features
  parentalControlAvailable: features.includes('parentalControl'),
  kidsAvailable: features.includes('kids'),
});
