import React from 'react';
import InvitationExpired from 'components/Error/InvitationExpired';
import { ApiError } from 'lib/error';
import { usePlan } from 'data/plan/usePlan';
import { useErrorPageView } from 'data/tracking/useErrorPageView';
import { useAnalyticsPageView } from 'data/tracking/useAnalyticsPageView';

type InvitationExpiredErrorProps = {
  error: ApiError;
};
const InvitationExpiredError = (props: InvitationExpiredErrorProps) => {
  const { error } = props;
  const { planType } = usePlan();

  useErrorPageView('invite_has_expired', error);
  useAnalyticsPageView(planType, '/error/invite-has-expired/');

  return <InvitationExpired />;
};

export default InvitationExpiredError;
