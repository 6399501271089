import styled from 'styled-components';
import { TypeList } from '@spotify-internal/encore-web';
import { media } from 'styles/mixins';
import * as tokens from 'styles';

const StepList = styled(TypeList.ol)`
  list-style: none;
  padding-left: 0;
  margin: 0;

  ${media.mobileMdUp} {
    display: flex;
    justify-content: center;
  }

  & > * {
    ${media.mobileMdUp} {
      max-width: 290px;
      text-align: center;
      flex-grow: 1;
    }
    counter-increment: item;
    margin-bottom: ${tokens.spacer32};
  }
  & > * + * {
    ${media.mobileMdUp} {
      margin-left: ${tokens.spacer40};
    }
  }
  & > *:before {
    content: counter(item);
    background: ${props => props.theme.bulletBackground};
    border-radius: 100%;
    color: ${props => props.theme.bulletColor};
    font-size: ${tokens.spacer24};
    width: 60px;
    height: 60px;
    text-align: center;
    display: block;
    margin: 0 auto ${tokens.spacer12};
    line-height: 60px;
  }
`;
export default StepList;
