import { useState, useEffect } from 'react';
import { mastheadApi } from 'services/api';
import { OptionalApiError, makeErrorFromApi } from 'lib/error';
import { Locale } from 'lib/i18n';

export const useMasthead = (locale: Locale) => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<OptionalApiError>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    mastheadApi
      .get('masthead', {
        params: {
          market: locale,
        },
      })
      .then(response => setData(response.data))
      .catch(e => {
        setError(makeErrorFromApi('MASTHEAD_ERROR', e));
      })
      .finally(() => setIsLoading(false));
  }, [locale]);

  return { data, isLoading, error };
};
