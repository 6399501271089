import { orderMembers } from 'lib/members';
import { getPermissions } from 'lib/permissions';
import { HomeHubData } from 'data/homeHub/useHomeHub';
import {
  AccessControl,
  Address,
  Country,
  Feature,
  HomeId,
  InviteToken,
  Member,
  MemberId,
  PlaceId,
  RegularMember,
} from 'types';
import type { CSRFClient } from 'lib/csrf';
import { ChildAccountSettings } from 'data/homeHub/useChildAccountSettings';
import { PlanType } from 'lib/plans';
import { FamilyAPI } from 'services/api';

export type HomeResponse = {
  name: string;
  planType: PlanType;
  maxCapacity: number;
  inviteToken: InviteToken;
  address: Address | null;
  homeId: HomeId;
  members: Array<Member>;
  accessControl: AccessControl;
  features: Array<Feature>;
};

export type AddressData = {
  address: Address;
  placeId: PlaceId | null;
};

export type RemoveMemberDataType = {
  id: MemberId;
};

export const appToApi = (data: any) => ({
  ...data,
  familyName: data.name,
});

export const apiToApp = (data: HomeResponse): HomeHubData => ({
  ...data,

  userCountry: data.members
    ? (data.members.find(m => m.isLoggedInUser) as RegularMember | undefined)
        ?.country ?? ''
    : '',

  members: orderMembers(data.members),
  permissions: getPermissions(data.accessControl, data.features),
});

export const loadHomeHub = (csrfClient: CSRFClient) => {
  return csrfClient
    .fetchWithToken(FamilyAPI.V1, {
      method: 'GET',
      url: 'home/',
    })
    .then(({ data }) => apiToApp(data));
};

export const onboardHomeHub = (
  updatedData: AddressData,
  csrfClient: CSRFClient,
) => {
  return csrfClient
    .fetchWithToken(FamilyAPI.V1, {
      method: 'POST',
      url: 'home/onboard',
      data: updatedData,
      headers: { 'content-type': 'application/json' },
    })
    .then(({ data }) => apiToApp(data));
};

type JoinPayload = {
  address: string;
  placeId: string;
  inviteToken: string;
};

export const validateStatus = (status?: number) => status !== 409;

export const joinHomeHubWithAddress = (
  { address, placeId, inviteToken }: JoinPayload,
  csrfClient: CSRFClient,
) => {
  return csrfClient
    .fetchWithToken(FamilyAPI.V1, {
      url: 'member/',
      method: 'POST',
      data: { address, placeId, inviteToken },
      headers: { 'content-type': 'application/json' },
    })
    .then(({ data }) => apiToApp(data));
};

export const removeFromHomeHub = (
  { id }: RemoveMemberDataType,
  csrfClient: CSRFClient,
) => {
  return csrfClient
    .fetchWithToken(FamilyAPI.V1, {
      method: 'DELETE',
      url: `member/${id}`,
      data: { foo: 'bar' }, // Without a fake payload Content-Type is removed by axios
      headers: { 'Content-Type': 'application/json' },
    })
    .then(({ data }) => apiToApp(data));
};

export const changeParentalControl = (
  targetMemberId: MemberId,
  parentalControlLock: boolean,
  csrfClient: CSRFClient,
) => {
  return csrfClient.fetchWithToken(FamilyAPI.V1, {
    method: 'POST',
    url: 'parentalcontrol/',
    data: {
      targetMemberId,
      parentalControlLock,
    },
  });
};

export const getKidsControlSettings = (
  targetMemberId: MemberId,
  planManagersCountry: Country,
  csrfClient: CSRFClient,
) => {
  return csrfClient
    .fetchWithToken(FamilyAPI.V1, {
      method: 'GET',
      url: `kidscontrol/${targetMemberId}/?country=${planManagersCountry}`,
    })
    .then(({ data }) => ({
      video: data.parentalControlVideo.isEnabled,
      explicitContent: data.parentalControlExplicitContent.isEnabled,
    }));
};

export const changeKidsControlSettings = (
  targetMemberId: MemberId,
  planManagersCountry: Country,
  settings: ChildAccountSettings,
  csrfClient: CSRFClient,
) => {
  return csrfClient
    .fetchWithToken(FamilyAPI.V1, {
      method: 'PUT',
      url: `kidscontrol/?country=${planManagersCountry}`,
      data: {
        memberId: targetMemberId,
        isVideoEnabled: !!settings.video,
        isExplicitContentEnabled: !!settings.explicitContent,
      },
      headers: { 'Content-Type': 'application/json' },
    })
    .then(({ data }) => ({
      video: data.parentalControlVideo.isEnabled,
      explicitContent: data.parentalControlExplicitContent.isEnabled,
    }));
};
