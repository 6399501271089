import { usePlan } from 'data/plan/usePlan';
import { useRedirect } from 'data/redirect/useRedirect';
import { useTranslation } from 'data/translation/useTranslation';
import { ApiError, ErrorType, makeErrorFromApi } from 'lib/error';
import { buildUrlStrict } from 'lib/url';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { loadInviter } from 'data/inviter/api';
import { useError } from 'data/error/useError';
import { isErrorOfTypeAndCode } from 'data/error/utils';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Country, HomeId } from 'types';

export type RestrictionReason = 'FAILED_REVERIFICATION' | 'PLAN_HOPPED';

export type JoinRestriction = {
  reason: RestrictionReason;
  appliedUntil: string;
};

export type UserData = {
  country: Country;
  homeId: HomeId;
  isLoggedInUserMaster: boolean;
  planHoppingRestrictionsApply?: boolean;
  joinRestrictions?: Array<JoinRestriction>;
  checkFailureReason?: string;
  legalTermsUrl: string;
};

export const LOAD_INVITER_ERROR_TYPE: ErrorType = 'LOAD_INVITER_ERROR_TYPE';

export type UseInviterData = {
  inviteToken: string;
  isLoadingInviter: boolean;
  inviterData?: UserData;
};

const useInviter = (): UseInviterData => {
  const { locale } = useTranslation();
  const { planType } = usePlan();
  const { error, setError } = useError();

  const { query } = useRouter();
  const inviteToken = Array.isArray(query.token)
    ? query.token[0]
    : query.token!;

  const queryResult = useQuery<UserData, AxiosError<ApiError>>({
    queryKey: ['useInviter', inviteToken],
    queryFn: () => loadInviter(inviteToken),
    retry: false,
  });

  useEffect(() => {
    if (queryResult.status === 'error') {
      setError(makeErrorFromApi(LOAD_INVITER_ERROR_TYPE, queryResult.error));
    }
  }, [queryResult.data, queryResult.status, queryResult.error, setError]);

  useRedirect({
    route: buildUrlStrict(planType, 'HOME_HUB', locale),
    condition: isErrorOfTypeAndCode(
      error,
      LOAD_INVITER_ERROR_TYPE,
      'USER_ALREADY_JOINED',
    ),
  });

  return {
    inviteToken,
    isLoadingInviter: queryResult.isLoading,
    inviterData: queryResult.data,
  };
};

export default useInviter;
